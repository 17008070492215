
import { defineComponent, reactive } from 'vue';
import { CommonService } from '@hems/service';

export default defineComponent({
  name: 'ReportingPolicy',
  setup() {
    const commonService = new CommonService(window.axiosInstance.axios);
    const state = reactive({
      reportingEmailAddress: 'cybersecurity.eu@qcells.com',
      pgpKeyGuideSiteUrl: 'https://www.openpgp.org/software',
      pgpKeyFileName: 'qcells_cybersecurity_pgp.zip',
      securityTextUrl: '.well-known/security.txt',
      header: ['Product', 'Type', 'Additional Info 1', 'Additional Info 2'],
      data: [
        {
          product: 'Q.HOME CORE (Q.VOLT)',
          type: '-',
          additionalInfo1: 'Serial number',
          additionalInfo2: 'EMS Firmware Version',
        },
        {
          product: 'Q.HOME G4 (Q.HOME HUB)',
          type: '-',
          additionalInfo1: 'Serial number',
          additionalInfo2: 'EMS Firmware Version',
        },
        {
          product: 'Q.OMMAND Portal (Web)',
          type: 'Chrome, Edge, etc.',
          additionalInfo1: 'URL',
          additionalInfo2: '-',
        },
        {
          product: 'Q.OMMAND Home (App)',
          type: 'Android or iOS',
          additionalInfo1: 'OS Version',
          additionalInfo2: 'App Version',
        },
        {
          product: 'Q.OMMAND Pro (App)',
          type: 'Android or iOS',
          additionalInfo1: 'OS Version',
          additionalInfo2: 'App Version',
        },
      ],
    });

    const downloadPgpKeyFile = async () => {
      await commonService
        .getPgpKeyFile()
        .then((res) => {
          if (res.size === 0) {
            return;
          }
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res);
          link.download = state.pgpKeyFileName;
          link.click();
          link.remove();
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const openSecurityText = async () => {
      window.open(state.securityTextUrl, '_blank');
    };

    return {
      state,
      downloadPgpKeyFile,
      openSecurityText,
    };
  },
});
